<div class="container">
  <div *ngIf="metrics.length > 0">
    <strong>Retail Metrics</strong>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-3" *ngFor="let metric of metrics">
      <div class="m-2">
        <mat-card class="m-0 p-0">
          <mat-toolbar color="accent"
            >{{ metric.name | uppercase }}
          </mat-toolbar>
          <div class="text-center">
            <h1>{{ metric.count }}</h1>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <div *ngIf="b2bmetrics.length > 0">
    <strong>B2B Metrics</strong>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-3" *ngFor="let metric of b2bmetrics">
      <div class="m-2">
        <mat-card class="m-0 p-0">
          <mat-toolbar color="accent"
            >{{ metric.name | uppercase }}
          </mat-toolbar>
          <div class="text-center">
            <h1>{{ metric.count }}</h1>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
