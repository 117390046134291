import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { FirebaseAuthService } from 'tt-authentication-lib';
import { BrandingService } from 'tt-branding-lib';
import { CommonService } from 'tt-common-lib';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title: string = 'Application';
  copyright: string;

  _user: BehaviorSubject<any> = new BehaviorSubject({});
  readonly user$?: Observable<any>;

  @ViewChild('sidenav') sidenav?: MatSidenav;

  user: any;
  config: any;
  isHandset$?: Observable<boolean>;
  year: number = new Date().getFullYear();

  constructor(
    private authService: FirebaseAuthService,
    private branding: BrandingService,
    private titleService: Title,
    private commonService: CommonService
  ) {
    this.user$ = this.authService.currentuser$;
    this.authService.currentuser$.subscribe((user: any) => {
      this.user = user;
    });
    this.isHandset$ = this.commonService.isHandset$;
  }

  ngOnInit(): void {
    this.branding.config$.subscribe((data) => {
      this.config = data;
      this.title = this.config?.app?.title;
      this.copyright = this.config?.legal?.copyright;
      this.titleService.setTitle(this.config?.app?.title);
    });
  }

  closeSideNav(force: boolean) {
    if (Boolean(force) || this.sidenav?.mode === 'over') {
      this.sidenav?.close();
    }
  }
  onLogout(user: any) {
    this.authService.logout();
  }
}
