import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import {
  ImageAssetPicker,
  TtCommonLibModule,
  TextEditor,
  CachableHttpRequestInterceptor,
  DocumentAssetPicker,
} from 'tt-common-lib';
import { TtAuthenticationLibModule } from 'tt-authentication-lib';
import {
  BrandingService,
  ThemingService,
  TtBrandingLibModule,
} from 'tt-branding-lib';
import { TtWebStoreModule } from 'tt-web-store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { firebase, firebaseui, FirebaseUIModule } from 'firebaseui-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { CommonModule, DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { NgxImageCompressService } from 'ngx-image-compress';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { UserAuthGuard } from './authentication/guards/user-auth.guard';
import { HomeComponent } from './pages/components/home/home.component';
import { RouterModule } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { IvyCarouselModule } from 'angular-responsive-carousel';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    { provider: firebase.auth.GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD },
  ],
  tosUrl: '/store//terms',
  privacyPolicyUrl: '/store/privacy',
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
};

export function minlengthValidationMessage(err: any, field: any) {
  return `Should have atleast ${field.templateOptions.minLength} characters`;
}

export function maxlengthValidationMessage(err: any, field: any) {
  return `This value should be less than ${field.templateOptions.maxLength} characters`;
}

export function minValidationMessage(err: any, field: any) {
  return `This value should be more than ${field.templateOptions.min}`;
}

export function maxValidationMessage(err: any, field: any) {
  return `This value should be less than ${field.templateOptions.max}`;
}

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule,
    TtCommonLibModule,
    TtWebStoreModule,
    TtAuthenticationLibModule,
    TtBrandingLibModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    ReactiveFormsModule,
    MatButtonModule,
    MatSidenavModule,
    MatBadgeModule,
    MatButtonModule,
    AngularEditorModule,
    HttpClientModule,
    FormlyModule.forRoot({
      types: [
        { name: 'docassetpicker', component: DocumentAssetPicker },
        { name: 'assetpicker', component: ImageAssetPicker },
        { name: 'texteditor', component: TextEditor },
      ],
      extras: { lazyRender: true },
      validationMessages: [
        { name: 'required', message: 'This field is required' },
        { name: 'minlength', message: minlengthValidationMessage },
        { name: 'maxlength', message: maxlengthValidationMessage },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
      ],
    }),
    FormlyMaterialModule,
    MatNativeDateModule,
    IvyCarouselModule,
    FormlyMatDatepickerModule,
    HttpClientModule,
  ],
  providers: [
    BrandingService,
    UserAuthGuard,
    Title,
    DatePipe,
    HttpClient,
    NgxImageCompressService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachableHttpRequestInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (config: BrandingService) => () => config.load(),
      deps: [BrandingService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (themingService: ThemingService) => () =>
        themingService.initialize(),
      deps: [ThemingService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (themingService: ThemingService) => () =>
        themingService.initialize(),
      deps: [ThemingService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
