import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  LoginComponent,
  ManageUserComponent,
  ProfileComponent,
  UnauthorizedComponent,
} from 'tt-authentication-lib';
import { UserAuthGuard } from './authentication/guards/user-auth.guard';
import { HomeComponent } from './pages/components/home/home.component';
import { TtWebStoreModule, WebStoreComponent } from 'tt-web-store';
import {
  CollectionViewComponent,
  DeleteItemComponent,
  EditFormComponent,
  ExportCollectionComponent,
  ImportCollectionComponent,
  NewFormComponent,
  ViewCollectionItemComponent,
  FormDataViewComponent,
  CollectionContainerViewComponent,
  TtCollectionViewLibModule,
} from 'tt-collection-view-lib';
import { DataImporterComponent } from 'tt-web-store';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [UserAuthGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [UserAuthGuard],
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'login', component: LoginComponent },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [UserAuthGuard],
  },
  {
    path: 'user/manage',
    component: ManageUserComponent,
    canActivate: [UserAuthGuard],
  },
  {
    path: 'store',
    component: WebStoreComponent,
    canActivate: [UserAuthGuard],
    data: { mode: 'admin' },
    loadChildren: () => TtWebStoreModule,
  },
  {
    path: 'forms/list',
    component: FormDataViewComponent,
    canActivate: [UserAuthGuard],
  },
  {
    path: 'collection/manage',
    component: CollectionContainerViewComponent,
    canActivateChild: [UserAuthGuard],
    loadChildren: () => TtCollectionViewLibModule,
  },
  { path: 'data/manage', component: DataImporterComponent },
  {
    path: '**',
    component: HomeComponent,
    canActivate: [UserAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
