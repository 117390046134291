import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MetricsService } from 'src/app/services/metrics.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements AfterViewInit {
  metrics: any = [];
  b2bmetrics: any = [];

  constructor(private metricService: MetricsService) {}
  ngAfterViewInit(): void {
    let year = new Date().getFullYear();
    this.metricService.getMetricsData(year).subscribe((data) => {
      this.metrics = data;
    });
    this.metricService.getB2BMetricsData(year).subscribe((data) => {
      this.b2bmetrics = data;
    });
  }
}
