<div class="main-container">
  <div class="header">
    <mat-toolbar color="primary" class="text-white">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon *ngIf="!sidenav?.opened">menu</mat-icon>
        <mat-icon *ngIf="sidenav?.opened">menu_open</mat-icon>
      </button>
      <div class="ml-1">
        <div>{{ title }}</div>
        <div class="secondary-title">
          <small>Administrator</small>
        </div>
      </div>
      <span class="spacer"></span>
    </mat-toolbar>
  </div>
  <div class="app-container">
    <mat-sidenav-container fullscreen>
      <mat-sidenav
        fixedInViewport="true"
        class="app-sidenav"
        fixedTopGap="56"
        fixedBottomGap="56"
        #sidenav
        [opened]="!(isHandset$ | async)"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        style="width: 15em"
      >
        <tt-left-menu
          [user$]="user$"
          (logout)="onLogout($event)"
          (closeSideNav)="closeSideNav($event)"
        >
        </tt-left-menu>
      </mat-sidenav>
      <mat-sidenav-content>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  <div class="footer">
    <mat-toolbar class="bg-primary-light">
      <div class="spacer"></div>
      <div class="secondary-title">
        Copyright &copy; {{ year }} {{ copyright }}. All rights reserved.
      </div>
      <div class="spacer"></div>
    </mat-toolbar>
  </div>
</div>
