import { Injectable } from '@angular/core';
import { switchMap, catchError, take } from 'rxjs/operators';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { FirebaseAuthService } from 'tt-authentication-lib';
import { User } from 'tt-authentication-lib/lib/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserAuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: FirebaseAuthService
  ) {}

  guardRequest(user: User, role: string) {
    if (role === 'public') return of(true);
    else {
      if (user?.uid) {
        if (user.unregistered) {
          this.router.navigateByUrl('/unregistered');
          return of(false);
        }
        if (user.role && user.role.includes('admin')) {
          return of(true);
        } else {
          this.router.navigateByUrl('/unauthorized');
          return of(false);
        }
      } else {
        this.router.navigateByUrl('/login');
        return of(false);
      }
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.authService.currentuser$.pipe(
      take(1),
      switchMap((user: User) => {
        let role = childRoute?.data?.mode;
        return this.guardRequest(user, role);
      })
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.currentuser$.pipe(
      take(1),
      switchMap((user: User) => {
        let role = route?.data?.mode;
        return this.guardRequest(user, role);
      }),
      catchError(() => {
        this.router.navigateByUrl('/login');
        return of(false);
      })
    );
  }
}
